<template>
  <v-popover offset="10"
             :placement="popoverPlacement"
             trigger="hover"
             popoverClass="dashboard_popover"
             popoverWrapperClass="dashboard_popover_wrapper"
             popoverInnerClass="dashboard_popover_content"
             boundariesElement=".popover_trigger"
             @show="getTargetGroupDetails()">

    <div class="popover_trigger">      
      <span>{{productVariationsAmount}} {{$t('store.variations')}}</span>
    </div>

    <template slot="popover">
      <CCard  class="mb-0">
        <CCardBody>
          <CRow v-if="productVariationsLoading === true">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="item_tags">
                <b-tag v-for="variation in productVariations" v-bind:key="variation.loyalty_store_product_variation_id_external" class="info">
                  <span v-html="variation.variation_name"></span>
                </b-tag>
              </div>
            </CCol>
          </CRow>                    
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>
import axios from 'axios';
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'productVariationsPopover',
  props: ['productIdExternal', 'productVariationsAmount', 'productCurrency', 'popoverPlacement'],
  components: {
    loadingSpinner
  },
  data() {
    return {
      productVariationsLoading: false,
      productVariationsLoaded: false,
      productVariations: []
    }
  },
  methods: {
    getTargetGroupDetails() {
      if(this.productVariationsLoaded === false) {
        // Show the loader
        this.productVariationsLoading = true;
        // Get the target groups
        axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/product/' + this.productIdExternal + '/variations')
        .then(res => {
          this.productVariations = res.data.data.product_variations;
          
          setTimeout(function() {
            // Hide the loader
            this.productVariationsLoading = false;
            // Update the productVariationsLoaded value
            this.productVariationsLoaded = true;
          }.bind(this), 250);           
        })
        .catch(err => {
          console.error(err); 
        });
      }
    }
  }    
}
</script>